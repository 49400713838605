import "./App.css";
import bgLeft from "./assets/bg-left.jpg";
import bgRight from "./assets/bg-right.jpg";
import logoLeft from "./assets/own-logo-samseong.png";
import logoRight from "./assets/own-logo-guri.png";

function App() {
  return (
    <div className="App">
      <div className="flex flex-wrap w-[100vw] h-[100vh] dual-column-container">
        <a
          href="https://samseong.own-clinic.com/"
          className="w-[50%] h-full cursor-pointer relative btn"
        >
          <img
            className="w-full h-full"
            alt="삼성점 "
            src={bgLeft}
            style={{
              width: "100%",
              height: "100%",
              zIndex: 0,
              objectFit: "cover",
            }}
          />
          <div className="top-0 left-0 absolute flex w-full h-full items-center justify-center">
            <img className="logo" src={logoLeft} />
          </div>
          <div className="transition-all hover:opacity-0 opacity-30 bg-gray-950 absolute top-0 left-0 right-0 w-full h-full" />
        </a>
        <a
          href="https://guri.own-clinic.com/"
          className="w-[50%] h-full cursor-pointer relative btn"
        >
          <img
            alt="삼성점 "
            src={bgRight}
            style={{
              width: "100%",
              height: "100%",
              zIndex: 0,
              objectFit: "cover",
            }}
          />
          <div className="top-0 left-0 absolute flex w-full h-full items-center justify-center">
            <img className="logo" src={logoRight} />
          </div>
          <div className="transition-all hover:opacity-0 opacity-30 bg-gray-950 absolute top-0 left-0 right-0 w-full h-full" />
        </a>
      </div>
    </div>
  );
}

export default App;
